import React from 'react';

const Arrow = ({
  className = '',
  color = '#3182EB',
  height = '18',
  width = '18',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.22 9.007a.75.75 0 010-1.06l4.773-4.773a.75.75 0 111.06 1.06L5.561 7.727h9.189v1.5H5.56l3.493 3.493a.75.75 0 11-1.06 1.06L3.22 9.007z"
      fill={color}
    />
  </svg>
);

export default Arrow;
