import React from 'react';

const Linkedin = ({ width = '30px', height = '30px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-current text-gray-300 hover:text-black transition duration-200 ease-in-out"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9998 0.21875C23.1634 0.21875 29.7813 6.83667 29.7813 15.0003C29.7813 23.1639 23.1634 29.7818 14.9998 29.7818C6.83618 29.7818 0.218262 23.1639 0.218262 15.0003C0.218262 6.83667 6.83618 0.21875 14.9998 0.21875ZM10.9177 12.5223H7.85319V22.391H10.9177V12.5223ZM18.7189 12.2749C17.2279 12.2749 16.2285 13.0932 15.8194 13.8685H15.7765V12.5224H12.8373V22.391H15.8986V17.5045C15.8986 16.2177 16.1427 14.9738 17.736 14.9738C19.3062 14.9738 19.3293 16.4421 19.3293 17.587V22.391H22.3906V16.97C22.3906 14.3173 21.8204 12.2749 18.7189 12.2749ZM9.38381 7.60989C8.40363 7.60989 7.60904 8.40449 7.60904 9.38467C7.60904 10.3646 8.40387 11.1763 9.38381 11.1763C10.3634 11.1763 11.1586 10.3646 11.1586 9.38467C11.1586 8.40449 10.364 7.60989 9.38381 7.60989Z"
    />
  </svg>
);

export default Linkedin;
