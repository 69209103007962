import cn from 'classnames';
import React, { CSSProperties } from 'react';

const Section = ({
  children,
  className,
  spacing = 'my-8 sm:my-16 px-4 gap-4',
  customStyles,
}: {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  customStyles?: CSSProperties;
  spacing?: string;
}) => (
  <section
    className={cn(
      'max-w-6xl mx-auto flex flex-col items-center justify-center',
      spacing,
      className,
    )}
    style={customStyles}
  >
    {children}
  </section>
);

export default Section;
