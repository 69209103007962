import React from 'react';

const Twitter = ({ width = '30px', height = '30px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    className="fill-current text-gray-300 hover:text-black transition duration-200 ease-in-out"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9998 0.21875C23.1634 0.21875 29.7813 6.83667 29.7813 15.0003C29.7813 23.1639 23.1634 29.7818 14.9998 29.7818C6.83618 29.7818 0.218262 23.1639 0.218262 15.0003C0.218262 6.83667 6.83618 0.21875 14.9998 0.21875ZM17.8416 9.45959C16.1628 9.45959 14.8122 10.8196 14.8122 12.4891C14.8122 12.7235 14.8403 12.958 14.8872 13.1831C12.3736 13.0518 10.132 11.8513 8.64071 10.0129C8.37808 10.4632 8.22804 10.979 8.22804 11.5417C8.22804 12.5922 8.76263 13.5207 9.57862 14.0647C9.08156 14.046 8.61257 13.9053 8.20928 13.6802V13.7177C8.20928 15.1902 9.25039 16.4095 10.6385 16.6909C10.3853 16.7565 10.1133 16.7941 9.84128 16.7941C9.6443 16.7941 9.45673 16.7753 9.26913 16.7472C9.65368 17.9477 10.7698 18.82 12.1016 18.8481C11.0606 19.6641 9.75683 20.1424 8.34061 20.1424C8.08736 20.1424 7.8529 20.1331 7.60904 20.1049C8.95026 20.9678 10.5447 21.4649 12.2611 21.4649C17.8323 21.4649 20.8805 16.8503 20.8805 12.8455C20.8805 12.7142 20.8805 12.5828 20.8711 12.4515C21.462 12.0201 21.9779 11.4855 22.3905 10.8852C21.8465 11.1197 21.2557 11.2886 20.646 11.3542C21.2744 10.979 21.7527 10.3881 21.9778 9.6847C21.3963 10.0317 20.7398 10.285 20.0551 10.4163C19.5017 9.82538 18.7139 9.45959 17.8416 9.45959Z"
    />
  </svg>
);

export default Twitter;
