import ROUTES from '../routes';

export const CONNECTIONS = [
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/athena.svg`,
    title: 'Amazon Athena',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('athena-sql-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/azure-synapse.svg`,
    title: 'Azure Synapse',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('azure-synapse-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/bigquery.svg`,
    title: 'BigQuery',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('bigquery-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/clickhouse.svg`,
    title: 'ClickHouse',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('clickhouse-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/mysql.svg`,
    title: 'MySQL',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('mysql-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/postgresql.svg`,
    title: 'PostgreSQL',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('postgresql-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/presto.svg`,
    title: 'Presto',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('presto-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/redshift.svg`,
    title: 'Amazon Redshift',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('redshift-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/snowflake.svg`,
    title: 'Snowflake',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('snowflake-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/sql-server.svg`,
    title: 'SQL Server',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('sql-server-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/timescale.svg`,
    title: 'Timescale',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('timescale-client').asPath,
  },
  {
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/landing/first-section/trino.svg`,
    title: 'Trino',
    href: ROUTES.connectionGuide().href,
    asPath: ROUTES.connectionGuide('trino-client').asPath,
  },
];
